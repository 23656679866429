import { JZ } from "@buzzbike/ui/src/DesignSystem";
import { Box, BoxProps, HStack, useBreakpointValue } from "@chakra-ui/react";
import Image from "next/image";
import { FC } from "react";

import BikeImage from "../../public/static/images/signup-image.jpg";
import BikeEImage from "../../public/static/images/signup-image-bikeE.png";
import { CheckoutBreadcrumb } from "./Breadcrumb";
import SignupNavbar from "./navbar/SignupNavbar";
import styles from "./SignupLayout.module.css";

const SignupLayout: FC<{
  step?: string;
  containerStyles?: BoxProps;
  childrenStyles?: BoxProps;
  bikeE?: boolean;
}> = ({ children, step, containerStyles, childrenStyles, bikeE = false }) => {
  const isTablet = useBreakpointValue({
    base: true,
    md: false,
  });
  return (
    <>
      <SignupNavbar logoColor={isTablet ? "white" : bikeE ? "white" : "pink"} />
      <HStack alignItems="flex-start" {...containerStyles}>
        {!isTablet && (
          <Box
            clipPath="polygon(0 0, 100% 0, 90% 100%, 0 100%)"
            bgColor={bikeE ? JZ.Pink : "#f1f4fa"}
            flex={4 / 5}
            h="100vh"
            position="sticky"
            top={0}
          >
            <Image
              src={bikeE ? BikeEImage : BikeImage}
              alt="left side image"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              sizes="60vw"
              className={bikeE ? undefined : styles.leftSideImage}
            />
          </Box>
        )}

        <Box
          flex={1}
          px={[6, 16, 16, 16]}
          mt={{ base: 24, md: 0 }}
          {...childrenStyles}
        >
          <Box maxW={"2xl"} margin="auto">
            {step && <CheckoutBreadcrumb currentPage={step} />}
            {children}
          </Box>
        </Box>
      </HStack>
    </>
  );
};
export default SignupLayout;
