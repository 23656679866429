import {
  Breadcrumb as CharkaBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps as CharkaBreadcrumbProps,
  Text,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

import { ColourAtoms, JZ, typeForStyle } from "../../../ui/src/DesignSystem";

type Item = {
  name: string;
  label: string;
  description: string;
  path: string;
};

type BreadcrumbProps = CharkaBreadcrumbProps & {
  items: Item[];
  currentPage: string;
  colorVariant?: "primary" | "secondary";
};

type ColorVariant = Record<
  string,
  {
    textColor: string;
    borderColor: string;
    activeBorderColor: string;
    activedBorderColor: string;
  }
>;

const colorVariants: ColorVariant = {
  primary: {
    textColor: ColourAtoms.BB.Pink,
    borderColor: "#E2E8F0",
    activedBorderColor: JZ["Pink/1.5"],
    activeBorderColor: ColourAtoms.BB.Pink,
  },
  secondary: {
    borderColor: "#E2E8F0",
    textColor: ColourAtoms.Raw.Turquoise._500,
    activedBorderColor: ColourAtoms.Raw.Turquoise._300,
    activeBorderColor: ColourAtoms.BB.Emerald,
  },
};

export function Breadcrumb({
  items,
  currentPage,
  colorVariant = "primary",
}: BreadcrumbProps) {
  const color = colorVariants[colorVariant];
  const router = useRouter();

  return (
    <CharkaBreadcrumb spacing="8px" separator="" w="100%">
      {items.map(({ name, label, description, path }, index) => {
        const isCurrentPage = name === currentPage;
        const currentIndex = items.findIndex((i) => i.name === currentPage);
        return (
          <BreadcrumbItem
            key={name}
            w={`calc(100%/${items.length})`}
            isCurrentPage={isCurrentPage}
          >
            <BreadcrumbLink
              flex="1"
              borderTopWidth={4}
              borderTopColor={
                currentIndex > index
                  ? color.activedBorderColor
                  : color.borderColor
              }
              _hover={{
                textDecoration: "none",
                cursor: currentIndex > index ? "pointer" : "auto",
              }}
              _activeLink={{ borderTopColor: color.activeBorderColor }}
              pt={5}
              {...typeForStyle("PrimaryMedium", "Base")}
              onClick={() => {
                if (currentIndex > index) {
                  router.push({
                    pathname: path,
                    query: {
                      ...router.query,
                      isBack: true,
                    },
                  });
                }
              }}
            >
              <Text
                color={isCurrentPage ? color.textColor : "#718096"}
                _hover={currentIndex > index ? { color: color.textColor } : {}}
                textTransform="uppercase"
              >
                {label}
              </Text>
              <Text color={JZ.BuzzBlack} lineHeight={"18px"}>
                {description}
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </CharkaBreadcrumb>
  );
}

export function CheckoutBreadcrumb({ currentPage }: { currentPage: string }) {
  return (
    <Breadcrumb
      items={[
        {
          name: "step1",
          label: "Step 1",
          description: "Select bike & plan",
          path: "/a/60-plan",
        },
        {
          name: "step2",
          label: "Step 2",
          description: "Your details",
          path: "/a/70-account",
        },
        {
          name: "step3",
          label: "Step 3",
          description: "Cycling essentials",
          path: "/a/71-kitshop",
        },
        {
          name: "step4",
          label: "Step 4",
          description: "Checkout safely",
          path: "/a/80-pay",
        },
      ]}
      currentPage={currentPage}
    />
  );
}

export default Breadcrumb;
