import { ColourAtoms } from "@buzzbike/ui/src/DesignSystem";
import { Box, Button, Spinner, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSegment } from "hooks/useSegment";
import { GetServerSideProps } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  ClientSafeProvider,
  getProviders,
  getSession,
  signIn,
} from "next-auth/client";
import { NextSeo } from "next-seo";
import { useEffect, useLayoutEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import ButtonLink from "components/ButtonLink";
import { getKustomerToken } from "utils/api";
import { makeQueryString } from "utils/endpoints";
import { trackLogin } from "utils/tracking";

import FormInput from "../components/FormInput";
import SignupLayout from "../components/SignupLayout";

const schema = yup.object().shape({
  username: yup
    .string()
    .email("Please provide a valid email")
    .required("This field is required"),
  password: yup.string().required("This field is required"),
});

export default function SignIn({
  callbackUrl,
  email,
  refresh_token,
}: {
  providers: Record<string, ClientSafeProvider>;
  callbackUrl: string;
  email?: string;
  refresh_token?: string;
}) {
  const [error, setError] = useState<string | undefined>();
  const [isLogging, setIsLogging] = useState<boolean>();
  const segment = useSegment();
  const router = useRouter();
  const toast = useToast();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  const [username, password] = watch(["username", "password"]);
  const onSubmit = async ({
    username,
    password,
  }: yup.TypeOf<typeof schema>) => {
    const signInResponse = await signIn("credentials", {
      username,
      password,
      redirect: false,
    });
    if (signInResponse) {
      if (signInResponse.error) {
        setError(signInResponse.error);
      } else {
        trackLogin(segment);
        const { data: kustomer_token } = await getKustomerToken();
        if (kustomer_token) {
          localStorage.setItem("kustomer_token", kustomer_token);
        }
        router.push({ pathname: callbackUrl || "/", query: router.query });
      }
    } else {
      setError("Unable to login");
    }
  };

  useEffect(() => {
    setError(undefined);
  }, [username, password]);

  useEffect(() => {
    if (email) {
      toast({
        title: "The email you entered is already exist, please log in",
        position: "top-right",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [email, toast]);

  useLayoutEffect(() => {
    async function signInWithRF() {
      if (refresh_token) {
        setIsLogging(true);
        const signInResponse = await signIn("credentials", {
          refresh_token,
          redirect: false,
        });
        if (signInResponse) {
          if (!signInResponse.error) {
            trackLogin(segment);
            const { data: kustomer_token } = await getKustomerToken();
            if (kustomer_token) {
              localStorage.setItem("kustomer_token", kustomer_token);
            }
            await router.push({
              pathname: callbackUrl || "/",
              query: router.query,
            });
          } else {
            setIsLogging(false);
          }
        }
      }
    }
    signInWithRF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLogging) {
    return (
      <SignupLayout
        containerStyles={{
          bgColor: ColourAtoms.BB.Emerald,
        }}
      >
        <Box
          color="white"
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mt={-24}
        >
          <Text variant="secondary4xl">You&#39;re logging in</Text>
          <Text my={2}>Please wait a few seconds</Text>
          <Spinner size="xl" color="white" />
        </Box>
      </SignupLayout>
    );
  }

  return (
    <>
      <NextSeo noindex nofollow title="Login - Buzzbike" />
      <SignupLayout
        childrenStyles={{
          py: { base: "6", md: "10" },
        }}
      >
        <Text textAlign="center" variant="secondary4xl">
          Login to Buzzbike
        </Text>
        <Box maxW={{ base: "96", md: "128" }} mx="auto" mt={8}>
          <FormProvider {...methods}>
            <form>
              <FormInput
                name="username"
                label="Email"
                type="email"
                defaultValue={email}
                autoComplete="username"
                autoCapitalize="off"
                autoCorrect="off"
              />
              <FormInput
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              <ButtonLink justifyContent="flex-end" isFullWidth>
                <Link href="/forgot-password">Forgot your password?</Link>
              </ButtonLink>
              <Text color={ColourAtoms.BB.Pink}>{error}</Text>
              <Button
                mt={4}
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmitting}
                type="submit"
                isFullWidth={true}
              >
                Login
              </Button>

              <Text mt={4} textAlign="center">
                Don&apos;t have an account?{" "}
                <ButtonLink>
                  <Link href={`/a/50-city${makeQueryString(router.query)}`}>
                    Sign Up
                  </Link>
                </ButtonLink>
              </Text>
            </form>
          </FormProvider>
        </Box>
      </SignupLayout>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);
  const urlParams = context.query;
  const queryString = makeQueryString(urlParams);
  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: urlParams.callbackUrl
          ? `${urlParams.callbackUrl.toString()}${queryString}`
          : `/${queryString}`,
      },
    };
  }
  return {
    props: {
      providers: await getProviders(),
      callbackUrl: urlParams.callbackUrl,
      email: urlParams.email,
      refresh_token: urlParams.refresh_token,
    },
  };
};
