import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { FieldError, FieldValues, useController } from "react-hook-form";
import { Props as InputMaskProps } from "react-input-mask";
import { ReactNode } from "react-markdown";

import { UseControllerPropsNarrowing } from "../utils/formTyping";
import InputMask from "./InputMask";

type FormInputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  InputProps,
  "name" | "defaultValue" | "value"
> & {
  label?: string;
  rightLabel?: React.ReactNode;
  helperText?: string;
  customError?: ReactNode;
  inputRightElement?: ReactNode;
  inputMaskProps?: Pick<
    InputMaskProps,
    | "mask"
    | "maskPlaceholder"
    | "alwaysShowMask"
    | "inputRef"
    | "beforeMaskedStateChange"
  >;
} & UseControllerPropsNarrowing<string | undefined, TFieldValues>;

const FormInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormInputProps<TFieldValues>
) => {
  const {
    name,
    label,
    rightLabel,
    helperText,
    inputMaskProps,
    control,
    defaultValue,
    customError,
    inputRightElement,
    onBlur,
    ...restProps
  } = props;

  // TODO TS not able to realise a string is valid here (4.2.4)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultWithEmpty = (defaultValue ?? "") as any;

  const {
    field: { value, onBlur: onFieldBlur, ...restFieldProps },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    defaultValue: defaultWithEmpty,
  });

  return (
    <FormControl isInvalid={invalid || !!customError}>
      <HStack align="center" justify="space-between">
        <FormLabel htmlFor={name}>{label}</FormLabel>
        {rightLabel}
      </HStack>
      <InputGroup>
        <Input
          as={InputMask}
          mask=""
          id={name}
          value={value || ""}
          onBlur={(e) => {
            onFieldBlur();
            onBlur?.(e);
          }}
          {...restFieldProps}
          {...inputMaskProps}
          {...restProps}
        />
        {inputRightElement && (
          <InputRightElement>{inputRightElement}</InputRightElement>
        )}
      </InputGroup>
      {}
      <FormErrorMessage>
        {error && (error as FieldError).message}
        {!error && customError}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default FormInput;
